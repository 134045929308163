import { Box } from "@mui/material";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";

const Logo = () => {
  const { content } = useContext(DataContext);
  const logo = content?.logo?.data;

  return (
    <Box sx={{ width: { xs: "15rem", xl: "17rem" } }}>
      <img
        style={{ width: "inherit" }}
        src={logo?.url as string}
        alt={logo?.description as string}
      />
    </Box>
  );
};

export default Logo;
