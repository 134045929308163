import {
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useMemo, useState } from "react";
import { Footer } from "./components/layout/Footer";
import { Header } from "./components/layout/Header";
import { ColorContext } from "./context/ColorContext";
import { DataContext, DataContextSchema } from "./context/DataContext";
import { darkTheme } from "./theme/dark";
import { lightTheme } from "./theme/light";

import { useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { COLOR_MODE, LANGUAGE } from "./const/global";
import { routes } from "./const/routes";
import { HEADER_SIZE_XL, HEADER_SIZE_XS } from "./const/sizes";
import { LanguageContext } from "./context/LanguageContext";
import { Language, LanguageCollection } from "./models/schema";
import { CategoryPage } from "./pages/CategoryPage";
import { HomePage } from "./pages/HomePage";
import {
  GET_LANGUAGES,
  GET_LANGUAGE_CATEGORIES,
  GET_LANGUAGE_GENERAL_DATA,
  GET_LANGUAGE_HOME,
} from "./querries/querries";

const ScrollPositionRestore = () => {
  const location = useLocation();

  // restore scroll position on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <></>;
};

const Content = ({
  language,
  languages,
}: {
  language: string;
  languages: Language[];
}) => {
  const { data: general, loading: loadingGeneral } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGE_GENERAL_DATA, {
    variables: {
      code: language,
    },
    fetchPolicy: "no-cache",
  });

  const { data: home, loading: loadingHome } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGE_HOME, {
    variables: {
      code: language,
    },
    fetchPolicy: "no-cache",
  });

  const { data: categories, loading: loadingCategories } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGE_CATEGORIES, {
    variables: {
      code: language,
    },
    fetchPolicy: "no-cache",
  });

  const generalData = general?.languageCollection?.items[0];
  const homeData = home?.languageCollection?.items[0];
  const categoriesData = categories?.languageCollection?.items[0];
  const content: Language = {
    ...(generalData as Language),
    ...(homeData as Language),
    ...(categoriesData as Language),
  };

  if (loadingGeneral || loadingHome || loadingCategories) {
    return (
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  const appData: DataContextSchema = {
    availableLanguages: languages,
    content,
  };

  return (
    <>
      {content && (
        <DataContext.Provider value={appData}>
          <Header />
          <Box
            sx={{
              flex: "1 0 auto",
              flexDirection: "column",
              display: "flex",
              pt: { xs: HEADER_SIZE_XS, xl: HEADER_SIZE_XL },
            }}
          >
            <Routes>
              <Route path={routes.home} element={<HomePage />}></Route>
              {content.categoriesCollection?.items?.map((category) =>
                category ? (
                  <Route
                    key={category.id as string}
                    path={category.id as string}
                    element={<CategoryPage data={category} />}
                  ></Route>
                ) : (
                  <></>
                )
              )}
              <Route path="*" element={<Navigate to={routes.home} replace />} />
            </Routes>
          </Box>
          <Footer />
        </DataContext.Provider>
      )}
    </>
  );
};

const App = () => {
  // Theme

  const storedMode = sessionStorage.getItem(COLOR_MODE) as PaletteMode;

  const [colorMode, setColorMode] = useState<PaletteMode>(
    storedMode || ("light" as PaletteMode)
  );

  const theme = useMemo(
    () => createTheme(colorMode === "light" ? lightTheme : darkTheme),
    [colorMode]
  );

  const selectedColorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setColorMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  useEffect(() => {
    sessionStorage.setItem(COLOR_MODE, colorMode);
  }, [colorMode]);

  // Data

  const { data: languageCollection } = useQuery<{
    languageCollection: LanguageCollection;
  }>(GET_LANGUAGES);

  const storedLanguage = sessionStorage.getItem(LANGUAGE);
  const [language, setLanguage] = useState<string | null>(storedLanguage);

  const selectedLanguage = useMemo(
    () => ({
      selectedLanguage: language,
      setLanguage: (lang: string) => {
        setLanguage(lang);
      },
    }),
    [language]
  );

  useEffect(() => {
    if (!language) {
      setLanguage("en");
    }
  }, [languageCollection, language]);

  useEffect(() => {
    if (language) {
      sessionStorage.setItem(LANGUAGE, language);
    }
  }, [language]);

  return (
    <ColorContext.Provider value={selectedColorMode}>
      <LanguageContext.Provider value={selectedLanguage}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <Router>
            <ScrollPositionRestore />
            {language && (
              <Content
                language={language}
                languages={
                  languageCollection?.languageCollection?.items as Language[]
                }
              />
            )}
          </Router>
        </ThemeProvider>
      </LanguageContext.Provider>
    </ColorContext.Provider>
  );
};

export default App;
