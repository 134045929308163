import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  AlertTitle,
  Collapse,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { colors } from "../../theme/colors";
import { Button } from "../utils/Button";

const SideNote = ({ title, steps }: { title?: string; steps?: string[] }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Collapse in={open}>
        <Alert
          severity="info"
          sx={{
            position: "fixed",
            bottom: { xs: 0, md: "8rem" },
            right: { xs: "auto", md: "3rem" },
            width: { xs: 1, md: "auto" },
            p: 3,
            zIndex: 100,
            overflow: "hidden",
            borderRadius: { xs: 0, md: "30px" },
            boxShadow:
              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>{title}</AlertTitle>

          {steps?.map((step, i) => (
            <Typography key={i}>
              ✅&nbsp;{step}
              <br />
            </Typography>
          ))}
        </Alert>
      </Collapse>

      <Button
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          m: 5,
          p: 3,
          zIndex: 80,
          borderRadius: 100,
          backgroundColor:
            theme.palette.mode === "dark" ? colors.darkPink : colors.pink,
        }}
        onClick={() => {
          setOpen(!open);
        }}
        endIcon={<InfoIcon />}
      ></Button>
    </>
  );
};

export default SideNote;
