import React from "react";

interface LanguageContextSchema {
  selectedLanguage: string | null;
  setLanguage: (lang: string) => void;
}

export const LanguageContext = React.createContext<LanguageContextSchema>(
  {} as LanguageContextSchema
);
