import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Box, SxProps, Typography } from "@mui/material";
import { Scalars } from "../../models/schema";

const RenderContent = ({
  content,
  options = {},
  sx = {},
}: {
  content: Scalars["JSON"];
  options?: Options;
  sx?: SxProps;
}) => {
  const defaultOptions: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h1" textAlign="center" mb={5}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h2" textAlign="center" mb={5}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography variant="h3" textAlign="center" mb={5}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography variant="h4" textAlign="center" mb={5}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography variant="h5" textAlign="center" mb={5}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography variant="h6" textAlign="center" mb={5}>
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography>{children}</Typography>
      ),
    },
  };

  const merged: Options = {
    renderNode: {
      ...defaultOptions.renderNode,
      ...options.renderNode,
    },
    renderMark: {
      ...defaultOptions.renderMark,
      ...options.renderMark,
    },
    renderText: options.renderText || defaultOptions.renderText,
  };

  return (
    <Box sx={sx}>
      {typeof content === "string"
        ? content
        : documentToReactComponents(content, merged)}
    </Box>
  );
};

export default RenderContent;
