import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { getButton } from "../../querries/buttons";
import { NavButton } from "../utils/NavButton";

export const Card = ({
  title,
  description,
  color,
  darkModecolor,
  route,
}: {
  title: string;
  description: string;
  color: string;
  darkModecolor: string;
  route: string;
}) => {
  const theme = useTheme();

  const { content } = useContext(DataContext);
  const buttons = content?.buttonsCollection?.items;

  return (
    <Paper
      sx={{
        borderRadius: "30px",
        width: "100%",
        height: "100%",
        background: theme.palette.mode === "dark" ? darkModecolor : color,
        p: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" textAlign="center" mb={3}>
        {title}
      </Typography>
      <Typography mb={3}>{description}</Typography>
      <Box
        sx={{
          mt: 1,
          width: 1,
          flexGrow: 1,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            mt: 1,
            width: 1,
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            "& a": {
              width: { xs: 1, lg: "auto" },
            },
          }}
        >
          <NavButton
            route={route}
            label={getButton(buttons, "readMore")}
            endIcon={<ArrowForwardIcon />}
            sx={{
              width: { xs: 1, lg: "auto" },
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};
