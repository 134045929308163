import { createTheme, ThemeOptions } from "@mui/material/styles";
import { colors } from "./colors";

export const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: colors.pink,
    },
    secondary: {
      main: colors.lightGray,
    },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontFamily: "Merriweather",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Merriweather",
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "Merriweather",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Merriweather",
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Merriweather",
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Merriweather",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1.2rem",
    },
  },
};

export const defaultTheme = createTheme(themeConfig);
