import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link as FooterLink } from "../../models/schema";
import { colors } from "../../theme/colors";

export const FooterLinks = ({ links }: { links: Array<FooterLink> }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        {links.map((link, i) => (
          <Link
            key={i}
            href={link.value as string}
            sx={{
              color: colors.sparring,
            }}
            target="_blank"
          >
            <Typography fontWeight="bold">{link.name}</Typography>
          </Link>
        ))}
      </Box>
    </Box>
  );
};
