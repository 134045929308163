import { BLOCKS, Text } from "@contentful/rich-text-types";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Children from "react-children-utilities";
import { ExpandableCard } from "../components/category/ExpandableCard";
import SectionSelector from "../components/category/SectionSelector";
import SideNote from "../components/category/SideNote";
import { Button } from "../components/utils/Button";
import ContactForm from "../components/utils/ContactForm";
import ContentWrapper from "../components/utils/ContentWrapper";
import RenderContent from "../components/utils/RenderContent";
import { getHeaderOffset } from "../const/sizes";
import { Card, Category, Maybe, Scalars, Section } from "../models/schema";
import { colors } from "../theme/colors";

export const CategoryPage = ({ data }: { data: Category }) => {
  const theme = useTheme();

  const [selected, setSelected] = useState(0);

  if (!data) {
    return <>No content</>;
  }

  const description = data.description?.json;
  const sections = data.sectionsCollection?.items;

  return (
    <>
      {data?.advanced && (
        <SideNote
          title={data?.sidenoteTitle as string}
          steps={data?.sidenoteSteps as string[]}
        />
      )}

      <ContentWrapper
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? colors.darkPink : colors.pink,
        }}
      >
        <Typography variant="h4" textAlign="center" mb={5}>
          {data.title}
        </Typography>
        <Paper
          elevation={3}
          sx={{
            borderRadius: "30px",
            width: "100%",
            maxWidth: "md",
            p: 5,
          }}
        >
          <RenderContent
            content={description}
            options={{
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Typography fontSize="1.25rem" fontFamily="Merriweather">
                    {children}
                  </Typography>
                ),
              },
            }}
          />

          {!data?.advanced && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                mt: 5,
              }}
            >
              {sections
                ?.filter((section) => section?.hasNavigation)
                .map((section, i) => (
                  <Box
                    sx={{ mr: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}
                    key={i}
                  >
                    <AnchorLink
                      key={i}
                      offset={getHeaderOffset()}
                      href={`#${section?.id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Button
                        label={section?.name as string}
                        sx={{ width: { xs: 1, lg: "auto" } }}
                        variant="outlined"
                      ></Button>
                    </AnchorLink>
                  </Box>
                ))}
            </Box>
          )}
        </Paper>
      </ContentWrapper>

      {data?.advanced && (
        <ContentWrapper>
          <SectionSelector
            sections={
              sections?.filter((section) => section?.hasNavigation) as Section[]
            }
          />
        </ContentWrapper>
      )}

      {data.contactUs?.json && (
        <ContentWrapper sx={{ py: 0, mt: -5 }}>
          <Box sx={{ mb: 8 }}>
            <RenderContent content={data?.contactUs?.json} />
          </Box>
          <ContactForm />
        </ContentWrapper>
      )}

      {data?.advanced && <Divider sx={{ mt: 5, mb: -5 }} />}

      {sections
        ?.filter((section) => section?.hasContent)
        .map((section, i) => (
          <ContentWrapper
            key={i}
            sx={{
              backgroundColor:
                i % 2 === 1
                  ? theme.palette.mode === "dark"
                    ? colors.darkPink
                    : colors.pink
                  : "default",
            }}
          >
            <Box
              sx={{
                "& ul": {
                  borderRadius: "30px",
                  overflow: "hidden",
                  padding: 0,
                },
              }}
            >
              <Box
                id={section?.id as string}
                sx={{
                  "& > :not(template) ~ :not(template)": {
                    mb: 5,
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? i % 2 === 1
                          ? colors.black
                          : colors.darkPink
                        : i % 2 === 1
                        ? colors.white
                        : colors.pink,
                    borderRadius: 60,
                    p: 3,
                    width: "fit-content",
                    mx: "auto",
                    mb: { xs: 3, md: 5 },
                  }}
                >
                  <Typography
                    fontFamily="Merriweather"
                    fontSize={{ xs: "1.3rem", md: "1.5rem" }}
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {section?.name}
                  </Typography>
                </Box>
                <RenderContent
                  content={section?.content?.json}
                  options={{
                    renderNode: {
                      [BLOCKS.HEADING_6]: (node, children) => (
                        <Typography variant="h6" fontWeight={100} mb={5}>
                          {children}
                        </Typography>
                      ),
                      [BLOCKS.UL_LIST]: (node, children) => {
                        const childrenArr = Children.toArray(children);
                        const items = childrenArr
                          .map((child) => {
                            const [title, description] =
                              Children.onlyText(child).split(":");

                            return title && description
                              ? ({
                                  title: title as Maybe<Scalars["String"]>,
                                  description: {
                                    json: description as Maybe<
                                      Scalars["String"]
                                    >,
                                  },
                                } as Card)
                              : null;
                          })
                          .filter((item) => !!item) as Card[];

                        return (
                          <Box sx={{ mt: 5 }}>
                            <ExpandableCard
                              multiple={true}
                              items={items}
                              lightColor={
                                i % 2 === 1 ? colors.white : colors.pink
                              }
                              darkColor={
                                i % 2 === 1 ? colors.black : colors.darkPink
                              }
                            />
                          </Box>
                        );
                      },
                      [BLOCKS.OL_LIST]: (node, children) => {
                        const childrenArr = Children.toArray(children);
                        const items = childrenArr.map((child) =>
                          Children.onlyText(child)
                        );

                        return (
                          <FormGroup sx={{ mb: 5 }}>
                            {items.map((content: string, index: number) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(_, checked) => {
                                        setSelected((selected) =>
                                          checked ? selected + 1 : selected - 1
                                        );
                                      }}
                                    />
                                  }
                                  label={content}
                                  key={index}
                                />
                              );
                            })}
                          </FormGroup>
                        );
                      },
                      [BLOCKS.PARAGRAPH]: (node, children) => {
                        const isSubscript = node.content.find(
                          (subNode) =>
                            !!(subNode as Text)?.marks.find(
                              (mark) => mark.type === "subscript"
                            )
                        );
                        const isSuperscript = node.content.find(
                          (subNode) =>
                            !!(subNode as Text)?.marks.find(
                              (mark) => mark.type === "superscript"
                            )
                        );

                        const content = Children.onlyText(children);

                        if (isSubscript) {
                          return selected > 0 && <strong>{content}</strong>;
                        }

                        if (isSuperscript) {
                          return selected === 0 && <strong>{content}</strong>;
                        }

                        return (
                          <p style={{ whiteSpace: "pre-wrap" }}>{children}</p>
                        );
                      },
                    },
                  }}
                />
                <Box sx={{ mt: 8 }}>
                  <RenderContent content={section?.contactUs?.json} />
                </Box>
                <ContactForm variant={i % 2 === 1 ? "primary" : "pink"} />
              </Box>
            </Box>
          </ContentWrapper>
        ))}
    </>
  );
};
