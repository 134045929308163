import DarkIcon from "@mui/icons-material/Brightness4";
import LightIcon from "@mui/icons-material/Brightness7";
import { useTheme } from "@mui/material";
import React, { useContext } from "react";
import { ColorContext } from "../../context/ColorContext";
import { DataContext } from "../../context/DataContext";
import { getButton } from "../../querries/buttons";
import { colors } from "../../theme/colors";
import { Button } from "./Button";

export const SwitchModeButton = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorContext);

  const { content } = useContext(DataContext);
  const buttons = content?.buttonsCollection?.items;

  const toggleMode = () => {
    colorMode.toggleColorMode();
  };

  const icon = theme.palette.mode === "dark" ? <LightIcon /> : <DarkIcon />;
  const label =
    theme.palette.mode === "dark"
      ? getButton(buttons, "lightMode")
      : getButton(buttons, "darkMode");
  const style = {
    color: theme.palette.mode === "dark" ? colors.black : colors.white,
    backgroundColor:
      theme.palette.mode === "dark" ? colors.white : colors.black,
    ":hover": {
      backgroundColor:
        theme.palette.mode === "dark" ? colors.lightGray : colors.darkGray,
    },
    width: "100%",
    px: 3,
    my: 1,
  };

  return (
    <Button
      onClick={toggleMode}
      startIcon={icon}
      label={label}
      sx={style}
    ></Button>
  );
};
