import { Box, SxProps } from "@mui/material";
import { FC } from "react";

const ContentWrapper: FC<{
  children?: React.ReactNode;
  id?: string;
  sx?: SxProps;
  breakpoint?: string;
}> = ({ children, id, sx = {}, breakpoint = "md" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        px: 5,
        py: 10,
        justifyContent: "center",
        ...sx,
      }}
      id={id}
    >
      <Box sx={{ maxWidth: breakpoint, width: "100%" }}>{children}</Box>
    </Box>
  );
};

export default ContentWrapper;
