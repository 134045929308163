import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { Link } from "react-router-dom";

export const Sparring = () => {
  const { content } = useContext(DataContext);
  const supportedBy = content?.supportedBy;
  const sparringLogo = content?.sparring?.data;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        "& > :not(template) ~ :not(template)": {
          mt: 2,
        },
      }}
    >
      <Typography>{supportedBy}</Typography>
      <Link to="https://sparring.io/" target="_blank">
        <img
          style={{ width: "10rem" }}
          src={sparringLogo?.url as string}
          alt={sparringLogo?.description as string}
        />
      </Link>
    </Box>
  );
};
