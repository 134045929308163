import { useTheme } from "@mui/material";
import { useContext } from "react";
import { Anchors } from "../../const/anchors";
import { DataContext } from "../../context/DataContext";
import { colors } from "../../theme/colors";
import ContactForm from "../utils/ContactForm";
import ContentWrapper from "../utils/ContentWrapper";
import RenderContent from "../utils/RenderContent";

export const EUExpansion = () => {
  const theme = useTheme();
  const { content } = useContext(DataContext);
  const euExpansion = content?.home?.euExpansion?.json;

  return (
    <ContentWrapper
      id={Anchors.EU_EXPANSION}
      sx={{
        backgroundColor:
          theme.palette.mode === "dark" ? colors.darkPink : colors.pink,
      }}
    >
      <RenderContent content={euExpansion} />

      <ContactForm variant="primary" />
    </ContentWrapper>
  );
};
