import { BLOCKS, Text } from "@contentful/rich-text-types";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Anchors } from "../../const/anchors";
import { getHeaderOffset } from "../../const/sizes";
import { DataContext } from "../../context/DataContext";
import { getButton } from "../../querries/buttons";
import { colors } from "../../theme/colors";
import { Button } from "../utils/Button";
import ContentWrapper from "../utils/ContentWrapper";
import RenderContent from "../utils/RenderContent";

export const Intro = () => {
  const theme = useTheme();
  const { content } = useContext(DataContext);
  const title = content?.home?.title;
  const about = content?.home?.about;
  const img = content?.home?.aboutImage?.data?.url;
  const buttons = content?.buttonsCollection?.items;

  return (
    <ContentWrapper
      sx={{
        backgroundColor:
          theme.palette.mode === "dark" ? colors.darkPink : colors.pink,
      }}
      breakpoint="xl"
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ width: 1, mr: { xs: 0, md: 5 } }}>
            <Typography
              fontFamily="Merriweather"
              fontSize={{ xs: "2rem", md: "3rem" }}
              fontWeight="bold"
              mb={{ xs: 5, md: 10 }}
            >
              {title}
            </Typography>

            <RenderContent
              content={about?.json}
              options={{
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (node, children) => (
                    <Typography
                      fontFamily="Merriweather"
                      fontSize={{ xs: "1rem", md: "2rem" }}
                    >
                      {node.content.map((subNode, index) => {
                        const isBold = (subNode as Text)?.marks.find(
                          (mark) => mark.type === "bold"
                        );

                        const value = (subNode as Text).value;
                        const letterMatch = ["C", "A", "T"].includes(value);

                        const letterColor = {
                          C: colors.catBlue,
                          A: colors.catRed,
                          T:
                            theme.palette.mode === "dark"
                              ? colors.black
                              : colors.white,
                        };

                        const shadowColor =
                          theme.palette.mode === "dark"
                            ? colors.white
                            : colors.black;

                        return isBold && letterMatch ? (
                          <Box
                            key={index}
                            component="span"
                            fontWeight="bold"
                            sx={{
                              mx: "2px",
                              color: letterColor[value as "C" | "A" | "T"],
                              textShadow: `3px 3px 0 ${shadowColor},
                                        -1px -1px 0 ${shadowColor},  
                                         1px -1px 0 ${shadowColor},
                                        -1px 1px 0 ${shadowColor},
                                         1px 1px 0 ${shadowColor}`,
                            }}
                          >
                            {value}
                          </Box>
                        ) : (
                          <Box
                            key={index}
                            component="span"
                            fontWeight={isBold ? "bold" : "normal"}
                            mx="2px"
                          >
                            {value}
                          </Box>
                        );
                      })}
                    </Typography>
                  ),
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                my: 5,
                "& > *": {
                  mb: { xs: 2, md: 0 },
                  mr: { xs: 0, md: 2 },
                },
              }}
            >
              <AnchorLink
                offset={getHeaderOffset()}
                href={`#${Anchors.ABOUT}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  label={getButton(buttons, "learnMore")}
                  sx={{ width: { xs: 1, lg: "auto" } }}
                ></Button>
              </AnchorLink>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& img": {
                borderRadius: "30px",
                maxHeight: { xs: "20rem", md: "25rem" },
                maxWidth: { xs: "100%", md: "25rem" },
                objectFit: "cover",
              },
            }}
          >
            <img src={img as string} alt="Baloon cat" />
          </Box>
        </Box>
      </Container>
    </ContentWrapper>
  );
};
