import { SxProps, useTheme } from "@mui/material";
import { colors } from "../../theme/colors";
import { ActiveButtonStyle, NavButton } from "../utils/NavButton";

export const HeaderNavButton = ({
  label,
  route,
  mobile,
  setMobileNavOpen,
}: {
  route: string;
  label?: string;
  mobile?: boolean;
  setMobileNavOpen?: (open: boolean) => any;
}) => {
  const theme = useTheme();
  const active: ActiveButtonStyle = {
    variant: "transparent",
    sx: {
      backgroundColor:
        theme.palette.mode === "dark"
          ? colors.darkGray
          : "hsla(0, 0%, 0%, 0.2)",
    },
  };
  const sx: SxProps = {
    my: 1,
    width: { xs: 1, lg: "auto" },
  };

  return mobile ? (
    <NavButton
      onClick={() => mobile && setMobileNavOpen && setMobileNavOpen(false)}
      route={route}
      label={label}
      active={active}
      sx={sx}
    />
  ) : (
    <NavButton
      route={route}
      label={label}
      active={active}
      sx={sx}
    />
  );
};
