import { gql } from "@apollo/client";

export const GET_LANGUAGES = gql`
  {
    languageCollection {
      items {
        name
        code
        flag
      }
    }
  }
`;

export const GET_LANGUAGE_GENERAL_DATA = gql`
  query ($code: String!) {
    languageCollection(where: { code: $code }, limit: 1) {
      items {
        title
        subtitle
        logo {
          data {
          	title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
        buttonsCollection {
          items {
            id
            label
          }
        }
        contactUs {
          button
          title {
            json
          }
          description {
            json
          }
          email
          name
          message
          requiredMessage
          successMessage
          errorMessage
        }
        contactsTitle
        contactsCollection {
          items {
            name
            value
          }
        }
        servicesTitle
        servicesCollection {
          items {
            name
            value
          }
        }
        legalTitle
        legalCollection {
          items {
            name
            value
          }
        }
        supportedBy
        sparring {
          data {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
      }
    }
  }
`;

export const GET_LANGUAGE_HOME = gql`
  query ($code: String!) {
    languageCollection(where: { code: $code }, limit: 1) {
      items {
        home {
          title
          about {
            json
          }
          aboutImage {
            data {
              title
              description
              contentType
              fileName
              size
              url
              width
              height
            }
          }
          micaCollection {
            items {
              title
              description {
                json
              }
            }
          }
          cryptoAssetCollection {
            items {
              title
              description {
                json
              }
            }
          }
          categories {
            json
          }
          categoriesModal {
            json
          }
          euExpansion {
            json
          }
        }
      }
    }
  }
`;

export const GET_LANGUAGE_CATEGORIES = gql`
  query ($code: String!) {
    languageCollection(where: { code: $code }, limit: 1) {
      items {
        categoriesCollection(limit: 10) {
          items {
            id
            title
            description {
              json
            }
            homeTitle
            homeDescription
            advanced
            sectionsCollection(limit: 15) {
              items {
                id
                name
                hasContent
                hasNavigation
                description {
                  json
                }
                content {
                  json
                }
                contactUs {
                  json
                }
              }
            }
            contactUs {
              json
            }
            sidenoteTitle
            sidenoteSteps
          }
        }
      }
    }
  }
`;
