export const colors = {
  pink: "#FADDDA",
  blue: "#9CD6F7",
  green: "#99F4C6",
  aqua: "#8DF3E7",
  gray: "#A6A6A6",
  black: "#272727",
  white: "#FFFFFF",
  lightGray: "#D9D9D9",
  darkGray: "#888888",
  darkPink: "#725F5D",
  darkBlue: "#4F7488",
  darkGreen: "#65927B",
  darkAqua: "#508781",
  sparring: "#ff7075",
  darkSparring: "#a6494c",
  catRed: "#DB233D",
  catBlue: "#9CD6F7",
};

export const lightColors = [
  colors.pink,
  colors.green,
  colors.blue,
  colors.aqua,
];

export const darkColors = [
  colors.darkPink,
  colors.darkGreen,
  colors.darkBlue,
  colors.darkAqua,
];
