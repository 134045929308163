import TranslateIcon from "@mui/icons-material/Translate";
import { Box, Button as MuiButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Suspense, useContext, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { DataContext } from "../../context/DataContext";
import { LanguageContext } from "../../context/LanguageContext";
import { Language } from "../../models/schema";
import { Button } from "./Button";

const FlagIcon = ({ language }: { language: Language }) => {
  return <CircleFlag countryCode={language.flag as string} height="60" />;
};

export const LanguageSelector = () => {
  const [open, setOpen] = useState(false);
  const { availableLanguages } = useContext(DataContext);

  const languageCtx = useContext(LanguageContext);
  const language = availableLanguages?.find(
    (lang) => lang.code === languageCtx.selectedLanguage
  );

  return (
    <Suspense fallback={<></>}>
      {availableLanguages?.length > 1 && (
        <>
          <Button
            onClick={() => setOpen(true)}
            startIcon={<TranslateIcon />}
            variant="secondary"
            label={language?.name as string}
            sx={{ width: "100%", px: 3, my: 1 }}
          ></Button>

          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  "& > :not(template) ~ :not(template)": {
                    ml: 2,
                  },
                  justifyContent: "center",
                }}
              >
                {availableLanguages.map((lang) => (
                  <MuiButton
                    sx={{
                      backgroundColor: "transparent",
                      ":hover": {
                        filter: "brightness(80%)",
                      },
                      p: 0,
                      minWidth: "auto",
                      borderRadius: 100,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                    key={lang.code}
                    onClick={() => {
                      languageCtx.setLanguage(lang.code as string);
                      setOpen(false);
                    }}
                  >
                    <FlagIcon language={lang} />
                  </MuiButton>
                ))}
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Suspense>
  );
};
