import { Box, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { useContext, useState } from "react";
import { Anchors } from "../../const/anchors";
import { DataContext } from "../../context/DataContext";
import { Category } from "../../models/schema";
import { getButton } from "../../querries/buttons";
import { darkColors, lightColors } from "../../theme/colors";
import { Button } from "../utils/Button";
import ContactForm from "../utils/ContactForm";
import ContentWrapper from "../utils/ContentWrapper";
import RenderContent from "../utils/RenderContent";
import { Card } from "./Card";

export const Categories = () => {
  const [open, setOpen] = useState(false);

  const { content } = useContext(DataContext);
  const categories = content?.categoriesCollection?.items as Category[];
  const categoriesDesc = content?.home?.categories?.json;
  const categoriesModal = content?.home?.categoriesModal?.json;
  const buttons = content?.buttonsCollection?.items;

  return (
    <ContentWrapper id={Anchors.CATEGORIES}>
      <RenderContent content={categoriesDesc} />

      <Grid container spacing={4}>
        {categories.map((category, i) => (
          <Grid item xs={12} lg={6} key={i}>
            <Card
              key={category.id}
              title={category.homeTitle as string}
              description={category.homeDescription as string}
              route={category.id as string}
              color={lightColors[i % 4]}
              darkModecolor={darkColors[i % 4]}
            />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ width: 1, display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          label={getButton(buttons, "dontSeeYourCategory")}
          onClick={() => setOpen(true)}
          sx={{ mt: 5 }}
        />
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <RenderContent content={categoriesModal} />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="secondary"
            label={getButton(buttons, "close")}
            sx={{ px: 5, m: 2, mr: 1 }}
          />
          <ContactForm />
        </DialogActions>
      </Dialog>
    </ContentWrapper>
  );
};
