import { useContext } from "react";
import { Anchors } from "../../const/anchors";
import { DataContext } from "../../context/DataContext";
import { Card } from "../../models/schema";
import { colors } from "../../theme/colors";
import { ExpandableCard } from "../category/ExpandableCard";
import ContentWrapper from "../utils/ContentWrapper";

export const MicaRegulation = () => {
  const { content } = useContext(DataContext);
  const mica = content?.home?.micaCollection?.items;

  return (
    <ContentWrapper id={Anchors.ABOUT}>
      <ExpandableCard
        multiple={false}
        items={mica as Card[]}
        lightColor={colors.pink}
        darkColor={colors.darkPink}
      />
    </ContentWrapper>
  );
};
