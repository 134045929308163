import { Box } from "@mui/material";
import { Categories } from "../components/home/Categories";
import { EUExpansion } from "../components/home/EUExpansion";
import { Intro } from "../components/home/Intro";
import { MicaRegulation } from "../components/home/MicaRegulation";
import { CryptoAsset } from "../components/home/CryptoAsset";

export const HomePage = () => (
  <Box>
    <Intro />
    <MicaRegulation />
    <CryptoAsset />
    <Categories />
    <EUExpansion />
  </Box>
);
