import { useTheme } from "@mui/material";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { Card } from "../../models/schema";
import { ExpandableCard } from "../category/ExpandableCard";
import ContentWrapper from "../utils/ContentWrapper";
import { colors } from "../../theme/colors";

export const CryptoAsset = () => {
  const { content } = useContext(DataContext);
  const cryptoAssets = content?.home?.cryptoAssetCollection?.items;

  const theme = useTheme();

  return (
    <ContentWrapper
      sx={{
        backgroundColor:
          theme.palette.mode === "dark" ? colors.darkPink : colors.pink,
      }}
    >
      <ExpandableCard multiple={false} lightColor="" items={cryptoAssets as Card[]} />
    </ContentWrapper>
  );
};
