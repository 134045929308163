import { Alert, Box, Dialog, Snackbar } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useForm } from "react-hook-form";
import { DataContext } from "../../context/DataContext";
import { ContactUs } from "../../models/schema";
import { getButton } from "../../querries/buttons";
import { Button } from "./Button";
import RenderContent from "./RenderContent";

const ContactForm: React.FC<{
  variant?: "primary" | "secondary" | "pink" | "transparent" | "outlined";
  children?: JSX.Element;
}> = ({ variant, children }) => {
  const { content } = React.useContext(DataContext);
  const contactUs = content?.contactUs as ContactUs;
  const { button, description, email, name, message, requiredMessage } =
    contactUs;
  const buttons = content?.buttonsCollection?.items;

  const [open, setOpen] = React.useState(false);
  const [resultOpen, setResultOpen] = React.useState(false);
  const [result, setResult] = React.useState<"SUCCESS" | "ERROR" | undefined>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  type Form = {
    email: string;
    name: string;
    message: string;
  };

  const controls: Form = {
    email: "email",
    name: "name",
    message: "message",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    const secret = process.env.REACT_APP_EMAIL_API_SECRET;
    const emailApiUrl = process.env.REACT_APP_EMAIL_API_URL;

    if (!secret || !emailApiUrl) {
      throw new Error("Missing email api secret or url");
    }

    const formData = new URLSearchParams();
    formData.append('secret', secret);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('message', data.message);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formData.toString(),
    };

    fetch(emailApiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        handleClose();
        setResult("SUCCESS");
        setResultOpen(true);
      })
      .catch(() => {
        setResult("ERROR");
        setResultOpen(true);
      });
  };

  return (
    <Box>
      {children ? (
        <Box
          onClick={handleClickOpen}
          sx={{ cursor: "pointer", width: 1, textAlign: "center" }}
        >
          {children}
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleClickOpen}
            label={button as string}
            variant={variant || "pink"}
            sx={{
              width: { xs: 1, md: "auto" },
            }}
          />
        </Box>
      )}

      <Snackbar
        open={!!resultOpen}
        onClose={() => setResultOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setResultOpen(false)}
          severity={
            result === "SUCCESS"
              ? "success"
              : result === "ERROR"
                ? "error"
                : "info"
          }
          sx={{ width: "100%" }}
        >
          {result === "SUCCESS" && contactUs.successMessage}
          {result === "ERROR" && contactUs.errorMessage}
        </Alert>
      </Snackbar>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{button}</DialogTitle>
        <DialogContent>
          <RenderContent content={description?.json} />

          <Box component={"form"} mt={3}>
            <TextField
              autoFocus
              fullWidth
              required
              id={controls.email}
              label={email as string}
              type="email"
              variant="outlined"
              sx={{ my: 1 }}
              error={!!errors.email}
              helperText={errors.email && (errors.email.message as string)}
              {...register(controls.email, {
                required: requiredMessage as string,
              })}
            />
            <TextField
              fullWidth
              required
              id={controls.name}
              label={name as string}
              variant="outlined"
              sx={{ my: 1 }}
              error={!!errors.name}
              helperText={errors.name && (errors.name.message as string)}
              {...register(controls.name, {
                required: requiredMessage as string,
              })}
            />
            <TextField
              fullWidth
              multiline
              required
              rows={5}
              id={controls.message}
              label={message as string}
              variant="outlined"
              sx={{ my: 1 }}
              error={!!errors.message}
              helperText={errors.message && (errors.message.message as string)}
              {...register(controls.message, {
                required: requiredMessage as string,
              })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="secondary"
            label={getButton(buttons, "close")}
            sx={{ px: 5, m: 2, mr: 1 }}
          />
          <Button
            onClick={handleSubmit(onSubmit)}
            label={getButton(buttons, "send")}
            variant="pink"
            sx={{
              px: 5,
              m: 2,
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContactForm;
