import { SxProps } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";

import { getHeaderOffset } from "../../const/sizes";
import { Button } from "./Button";

export interface ActiveButtonStyle {
  variant?: "primary" | "secondary" | "transparent" | "outlined";
  sx?: SxProps;
}

export const NavButton = ({
  route,
  label,
  variant,
  active,
  isActive,
  startIcon,
  endIcon,
  sx,
  onClick,
}: {
  route: string;
  label?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: "primary" | "secondary" | "transparent" | "outlined";
  active?: ActiveButtonStyle;
  isActive?: boolean;
  sx?: SxProps;
  onClick?: () => any;
}) => {
  const scrollWithOffset = (el: Element) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = getHeaderOffset();
    window.scrollTo({ top: yCoordinate - yOffset, behavior: "smooth" });
  };

  let style: any = {};

  if (sx) {
    style = {
      ...style,
      ...sx,
    };
  }

  if (isActive && active?.sx) {
    style = {
      ...style,
      ...active.sx,
    };
  }

  return (
    <Link
      to={route}
      scroll={(el) => scrollWithOffset(el)}
      style={{ textDecoration: "none" }}
      onClick={onClick}
    >
      <Button
        variant={isActive ? active?.variant : variant}
        label={label}
        sx={style}
        startIcon={startIcon}
        endIcon={endIcon}
      ></Button>
    </Link>
  );
};
