import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Anchors } from "../../const/anchors";
import { routes } from "../../const/routes";
import { HEADER_SIZE_XL, HEADER_SIZE_XS } from "../../const/sizes";
import { DataContext } from "../../context/DataContext";
import { getButton } from "../../querries/buttons";
import { colors } from "../../theme/colors";
import Logo from "../home/Logo";
import { LanguageSelector } from "../utils/LanguageSelector";
import { SwitchModeButton } from "../utils/SwitchModeButton";
import { HeaderNavButton } from "./HeaderNavButton";
import { Sparring } from "./Sparring";

export const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = React.useState<boolean>(false);
  const theme = useTheme();

  const { content } = useContext(DataContext);
  const buttons = content?.buttonsCollection?.items;

  return (
    <AppBar
      position="fixed"
      sx={{
        p: { xs: 3, md: 5 },
        height: { xs: HEADER_SIZE_XS, xl: HEADER_SIZE_XL },
        boxShadow: "none",
        justifyContent: "center",
        backgroundColor:
          theme.palette.mode === "dark" ? colors.darkPink : colors.pink,
        backgroundImage: "none",
      }}
    >
      <Container maxWidth="xl">
        {/* Mobile */}
        <Box
          display={{ xs: "flex", xl: "none" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Link
            to={routes.home}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "70%",
            }}
          >
            <Logo />
          </Link>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: "auto" }}
            onClick={() => setMobileNavOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor="right"
            open={mobileNavOpen}
            onClose={() => setMobileNavOpen(false)}
            sx={{
              "& .MuiPaper-root, .MuiBackdrop-root": {
                backgroundColor: "transparent",
                width: 1,
                minHeight: 1,
              },
            }}
          >
            <Box
              sx={{
                p: 2,
                minHeight: 1,
                backdropFilter: "blur(5rem);",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <IconButton onClick={() => setMobileNavOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Divider sx={{ mb: 2 }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                  "& .": {
                    width: "100%",
                  },
                }}
              >
                <HeaderNavButton
                  mobile={true}
                  setMobileNavOpen={setMobileNavOpen}
                  route={routes.home}
                  label={getButton(buttons, "home")}
                />
                <HeaderNavButton
                  mobile={true}
                  setMobileNavOpen={setMobileNavOpen}
                  route={`${routes.home}#${Anchors.ABOUT}`}
                  label={getButton(buttons, "intro")}
                />
                <HeaderNavButton
                  mobile={true}
                  setMobileNavOpen={setMobileNavOpen}
                  route={`${routes.home}#${Anchors.CATEGORIES}`}
                  label={getButton(buttons, "getStarted")}
                />
                <HeaderNavButton
                  mobile={true}
                  setMobileNavOpen={setMobileNavOpen}
                  route={`${routes.home}#${Anchors.EU_EXPANSION}`}
                  label={getButton(buttons, "euExpansion")}
                />
                <HeaderNavButton
                  mobile={true}
                  setMobileNavOpen={setMobileNavOpen}
                  route={`${routes.home}#${Anchors.CONTACT_US}`}
                  label={getButton(buttons, "contact")}
                />

                <Divider sx={{ my: 3 }} />

                <LanguageSelector />
                <SwitchModeButton />
              </Box>
              <Box
                sx={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}
              >
                <Sparring />
              </Box>
            </Box>
          </Drawer>
        </Box>
        {/* Desktop */}
        <Box
          sx={{
            display: { xs: "none", xl: "flex" },
            alignItems: "center",
          }}
        >
          <Link
            to={routes.home}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Logo />
          </Link>
          <Box
            sx={{
              display: { xs: "none", xl: "flex" },
              alignItems: "center",
              ml: "auto",
              whiteSpace: "nowrap",
              "& > :not(template) ~ :not(template)": {
                ml: 2,
              },
            }}
          >
            <HeaderNavButton
              route={routes.home}
              label={getButton(buttons, "home")}
            />
            <HeaderNavButton
              route={`${routes.home}#${Anchors.ABOUT}`}
              label={getButton(buttons, "intro")}
            />
            <HeaderNavButton
              route={`${routes.home}#${Anchors.CATEGORIES}`}
              label={getButton(buttons, "getStarted")}
            />
            <HeaderNavButton
              route={`${routes.home}#${Anchors.EU_EXPANSION}`}
              label={getButton(buttons, "euExpansion")}
            />
            <HeaderNavButton
              route={`${routes.home}#${Anchors.CONTACT_US}`}
              label={getButton(buttons, "contact")}
            />
            <Divider orientation="vertical" sx={{ height: "4rem" }} />
            <LanguageSelector />
            <SwitchModeButton />
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};
