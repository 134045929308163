import React from "react";
import { Language } from "../models/schema";

export interface DataContextSchema {
  content: Language;
  availableLanguages: Language[];
}

export const DataContext = React.createContext<DataContextSchema>(
  {} as DataContextSchema
);
